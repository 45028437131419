<template>
  <div class="row m-0 align-items-center justify-content-center form-control_holder w-100">
    <div class="col-auto pe-0 ps-1">
      <icon-minus @click="decrement" size="size28" class="cursor" />
    </div>
    <div class="col p-0">
      <input
        class="form-control medium"
        :id="id"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :disabled="disabled"
        type="number"
        min="0"
        v-model="value"
      >
    </div>
    <div class="col-auto ps-0 pe-1">
      <icon-plus @click="increment" size="size28"  class="cursor"/>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    IconPlus: defineAsyncComponent(() => import('@/components/icons/IconPlus.vue')),
    IconMinus: defineAsyncComponent(() => import('@/components/icons/IconMinus.vue'))
  },
  name: 'TextField',
  props:
     {
       id: {
         type: String,
         default: Math.random().toString(20).substr(2, 6)
       },
       placeholder: String,
       label: String,
       modelValue: Number,
       size: String,
       color: String,
       autocomplete: String,
       disabled: Boolean
     },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  watch: {
    value (val) {
      if (val < 0) {
        this.value = 0
      }
    }
  },
  methods: {
    increment () {
      this.value++
    },
    decrement () {
      if (this.value > 0) {
        this.value--
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .form-control_holder {
    background-color: var(--green-color-dark);
    color: var(--gold-color);
    border-radius: 2rem;
    border: 2px solid var(--green-color-dark);
    font-size: 2.2rem;
    height: calc(3.5rem + 4px);
    font-weight: 500;
    text-align: center;
    display: inline-flex;
  }
  .form-control {
    background-color: transparent;
    border-radius: 0;
    border: none;
    font-size: inherit;
    height: 100%;
    font-weight: inherit;
    text-align: center;
    padding: 0;
    color: #fff;
    max-width: 50px;
    width: 100%;
  }
  .form-control_holder:disabled {
    background-color: var(--green-color-dark);
    opacity: 0.9;
    cursor: not-allowed;
  }
  .form-control_holder:focus-within {
    box-shadow: none;
    border: 2px solid var(--green-color-light);
    color: var(--green-color-dark);
    background-color: var(--gold-color);
  }
  .form-control:focus {
    border: none;
    outline: none;
    box-shadow: none;
    color: inherit;
    background-color: transparent;
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--green-color-light) !important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--green-color-light) !important;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--green-color-light) !important;
  }
  .cursor {
    border-radius: 50%;
    padding: 5px;
  }
  .cursor:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
</style>
